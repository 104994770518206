@import '~bootstrap/scss/bootstrap';
@import '~react-toastify/dist/ReactToastify.css';


* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

input:focus,
textarea:focus,
select:focus,
button:focus {
  outline: none;
  cursor: pointer;
}

*,
input,
button {
  font-family: 'Archivo', sans-serif;
}

.tableRow > * {
  width: 100%;
  text-align: center;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

a,
a:hover {
  text-decoration: none;
  color: var(--white-color-100);
}

p {
  margin: 0;
}

body {
  background: var(--black-color-300);
}

:root {
  --smaller-text: 12px;
  --small-text: 14px;
  --normal-text: 16px;
  --h4-text: 22px;
  --h3-text: 32px;
  --h2-text: 36px;
  --h1-text: 42px;

  --font-100: 100;
  --font-400: 400;
  --font-500: 500;
  --font-700: 700;

  --primary-color-100: #3071e4;
  --primary-color-200: #1b5dcf;

  --red-color-100: #d11306;
  --red-color-200: #bc1105;

  --yellow-color: #f4b740;
  --green-color: #35d83b;

  --white-color-100: #e8e8e8;
  --white-color-200: #d9d9d9;
  --white-color-300: #d1d1d1;

  --black-color-100: #2e2e2e;
  --black-color-200: #242424;
  --black-color-300: #1a1a1a;

  --gray-color-100: #949494;
  --gray-color-200: #8a8a8a;
  --gray-color-300: #808080;
  --gray-color-400: #757575;
  --gray-color-500: #6b6b6b;
  --gray-color-600: #4d4d4d;
  --gray-color-700: #424242;
  --gray-color-800: #383838;
  --gray-color-900: #242424;
  --gradient-gray-color: linear-gradient(110.41deg,
    #393939 -16.86%,
    #1d1d1d 108.52%);
  --shadow-color: 7px 4px 26px -3px rgba(0, 0, 0, 0.25);
}

.scroll-y-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  overflow-y: inherit;

  @media screen and (min-width: 840px) {
    height: 100%;
    max-height: 600px;
    overflow-y: auto;
    padding: 0px 12px 0 0;
  }
}

::-webkit-scrollbar {
  width: 7px;
  height: 9px;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.4);
  -webkit-border-radius: 10px;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  -webkit-border-radius: 10px;
  border-radius: 10px;
  background: var(--gray-color-400);
}


.boxClients::-webkit-scrollbar, .routedMeetingsTable::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}

.boxClients::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.4);
  -webkit-border-radius: 10px;
  border-radius: 10px;

}

.boxClients::-webkit-scrollbar-thumb {
  -webkit-border-radius: 10px;
  border-radius: 10px;
  background: var(--black-color-100);
}

/* ::-webkit-scrollbar-thumb:window-inactive {
  background: var(--gray-color-400);
} */

::-webkit-calendar-picker-indicator {
  filter: invert(1);
}

/* Editor de texto */
.ql-toolbar.ql-snow,
.ql-container.ql-snow {
  border: none;
}

.ql-snow.ql-toolbar {
  display: block;
  background: rgb(195, 195, 195);
  color: rgba(70, 70, 70, 1);
}

.ql-snow.ql-container {
  background: #f2f2f2;
  height: calc(100vh - 430px);
  max-height: 700px;
  font-size: 1rem;
  color: rgba(70, 70, 70, 1);
}

.createEventDescription .ql-container {
  height: 120px;
  color: #fff
}

.myClientsPanel:first-child {
  background: #2e2e2e;
  height: calc(100vh - 252px);
  width: 60%;
  font-size: 1rem;
}

.myClientsPanelModal {
  background: white;
  height: 400px;
  width: 100%;
  font-size: 1rem;
  border-left: .5px solid #1d63ddb2;
  border-right: .5px solid #1d63ddb2;
  border-top: .5px solid #1d63ddb2;


  .ql-snow.ql-container {
    flex: 1;
    background: white;
    height: calc(100vh - 450px);
    max-height: 250px;
    font-size: 1rem;
  }
}


.myClientsPanelMeetingDisclaimer:last-child {
  background: #2e2e2e;
  height: calc(100vh - 437px);
  width: 100%;
  font-size: 1rem;
}

.myClientsPanelMeetingDisclaimer .ql-snow.ql-container {
  height: calc(100vh - 482px);
}

//my Clients Panel mobile

.myClientsPanelMobile .ql-container {
  height: calc(100vh - 500px);
  color: #fff
}

.myClientsPanelMobileModal .ql-container {
  height: 100%;
  color: #fff
}

//===
/*Select*/
.rmsc {
  --rmsc-main: #4285f4;
  --rmsc-hover: #242424;
  --rmsc-selected: #242424;
  --rmsc-border: rgb(80, 79, 79);
  --rmsc-gray: white;
  --rmsc-bg: #242424;
  --rmsc-p: 10px;
  /* Spacing */
  --rmsc-radius: 4px;
  /* Radius */
  --rmsc-h: 30px !important;
  /* Height */
  width: 250px;
  border: none;
  z-index: 999999;
}

.rmsc.offers {
  --rmsc-radius: 2px;
  --rmsc-bg: #2e2e2e;
  width: 100%;
  --rmsc-border: none;
}

.boxBonusMessageAndDescription .ql-container {
  height: 80px;
  color: rgba(70, 70, 70, 1);
}


.boxBonusMessageAndDescription .ql-header {
  height: 20px;
}

.boxBonusMessagePublicOffering .ql-container {
  height: 90px;
  color: rgba(70, 70, 70, 1);
}


.meetingsTypesMenuCaptation::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}

.meetingsTypesMenuCaptation ::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.4);
  -webkit-border-radius: 10px;
  border-radius: 10px;

}

.meetingsTypesMenuCaptation ::-webkit-scrollbar-thumb {
  -webkit-border-radius: 10px;
  border-radius: 10px;
  background: var(--black-color-100);
}

.thinScrollbar::-webkit-scrollbar {
  width: 2px;
  height: 2px;
}

.thinScrollbar::-webkit-scrollbar-thumb {
  -webkit-border-radius: 10px;
  border-radius: 10px;
  background-color: #848484;
}

.thinScrollbar::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.4);
  -webkit-border-radius: 10px;
  border-radius: 10px;
}

.slimScrollbar::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}

.slimScrollbar ::-webkit-scrollbar-thumb {
  -webkit-border-radius: 10px;
  border-radius: 10px;
}

.slimScrollbar ::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.4);
  -webkit-border-radius: 10px;
  border-radius: 10px;
}

.triangulo {
  clip-path: polygon(0% 0%, 75% 0%, 98% 50%, 75% 100%, 0% 100%);
}

.triangulo-wrapper {
  clip-path: polygon(0% 0%, 75% 0%, 100% 50%, 75% 100%, 0% 100%);
}

.inputValorDoBem {
  height: 32px;
  box-sizing: border-box;
  background-color: "white";
  padding: 0 8px;
  width: fit-content;
  color: 'white';
  font-size: 14px;

  outline: 2px solid transparent;
  outline-offset: 2px;
  border-radius: 6px;

  &:hover {
    outline: 2px solid #3071e4;
  }

  // &:focus {
  //   outline: 2px solid #3071e4;
  //   border-color: var(--chakra-colors-transparent);
  //   background: var(--chakra-colors-background-500);
  // }
}

.EmbedContainer {
  margin-top: 2rem;
  min-height: 750px;
  min-width: 1330px;
}

.currencyInput {
  color: rgba(70, 70, 70, 1);
  border-radius: 5px;
  border: 1px solid #1d63ddb2;
  padding-left: 10px;
  padding-bottom: 1px;
  padding-top: 1px;
  background: #fff;

  ::placeholder {
    color: red !important;
  }
}

.reactQuillFullHeight {
  .ql-snow.ql-container {
    height: calc(100vh - 280px);
    font-size: 1rem;
  }
}

.input-light-mode {
  border: 1px solid gray;
  border-radius: 6px;
  padding: 6px;
  padding-left: 15px;
  font-size: 15px;
  color: rgba(70, 70, 70, 1);
}

.input-light-mode::-webkit-calendar-picker-indicator {
  filter: invert(0);
}

